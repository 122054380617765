<template>
    <div class =" text-justify col-span-12 mx-4 mt-4 rounded-lg  bg-TFL-cardDashboard p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] col-span-2 lg:col-span-1 shadow-[rgba(0,_0,_0,_0.10)_0px_25px_50px_-12px]">
        <p class="text-TFL-dashText1">اطلاعات حساب‌ها</p>
        <p class="text-sm mt-4 text-TFL-dashText2 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>کاربر گرامی در این قسمت  اطلاعات کلیه حساب‌ها و وضعیت هر یک قابل مشاهده است. </p>
        <p class="text-sm mt-1 text-TFL-dashText2 flex  mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>جهت ثبت درخواست‌های ریست، اکستند، فاز بعدی و دانلود سرتیفیکیت می‌توانید از این بخش اقدام نمایید  و پس از ثبت درخواست بلافصله به صورت خودکار به درخواست شما رسیدگی خواهد شد.</p>
    </div>
    <div class =" text-justify col-span-12 mx-4 mt-4 rounded-lg  bg-red-600 bg-opacity-30 p-4   col-span-2 lg:col-span-1 shadow-[rgba(0,_0,_0,_0.10)_0px_25px_50px_-12px]">
        <p class="text-sm mt-1 text-white flex  mx-2"><svg class="flex-shrink-0 w-4 h-4 text-white ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>معامله‌گران گرامی، توجه داشته باشید همانطور که ‌می‌دانید پس از دریافت خودکار حساب فاز دوم و یا حساب فاز ریل، موظف هستید در کمتر از 24 ساعت، درخواست تائیدیه نهایی حساب را تیکت ثبت نمایید. در صورت عدم ثبت درخواست تائیدیه نهایی، در هنگام برداشت سود، تمامی حساب‌های شما بررسی خواهد شد و در صورت نقض هرگونه از قوانین، حساب شما غیر فعال شده و مطابق متن قوانین عمل خواهد شد. در صورتی که تائیدیه نهایی مربوطه را دریافت ننمودید، هرچه سریع‌تر اقدام نمایید.</p>
    </div>
    <div v-if="accounts.length == 0 && gotResponse"  class="font-iranSans text-sm   space-x-4 m-2  p-4 text-right rounded-lg bg-blue-700 text-white bg-opacity-40 " >
           کاربر گرامی، در حال حاضر شما هیچ حساب معاملاتی ندارید.
        </div>

        <div v-if="!accountLoaded" class="grid grid-cols-12 m-2">
            <div class =" text-justify col-span-12 lg:col-span-6 mx-4 mt-4 rounded-lg  bg-TFL-cardDashboard  p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg   ">
                <div role="status" class=" p-4 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse  md:p-6">
                    <div class="flex items-center justify-between">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <span class="sr-only">Loading...</span>
                </div>
                
            </div>
            <div class =" text-justify col-span-12 lg:col-span-6 mx-4 mt-4 rounded-lg  bg-TFL-cardDashboard  p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg   ">
                <div role="status" class=" p-4 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse  md:p-6">
                    <div class="flex items-center justify-between">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <span class="sr-only">Loading...</span>
                </div>
                
            </div>
            <div class =" text-justify col-span-12 lg:col-span-6 mx-4 mt-4 rounded-lg  bg-TFL-cardDashboard  p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg   ">
                <div role="status" class=" p-4 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse  md:p-6">
                    <div class="flex items-center justify-between">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <span class="sr-only">Loading...</span>
                </div>
                
            </div>
            <div class =" text-justify col-span-12 lg:col-span-6 mx-4 mt-4 rounded-lg  bg-TFL-cardDashboard  p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg   ">
                <div role="status" class=" p-4 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse  md:p-6">
                    <div class="flex items-center justify-between">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <span class="sr-only">Loading...</span>
                </div>
                
            </div>
           
        </div>

    <div  v-if="accountLoaded" class="grid grid-cols-12 m-2">

        <div class=" col-span-12 "> 
            <div class="  grid grid-cols-2 ">
                <div v-for="(account, index) in accounts" :key="account.id" class="account-section bg-TFL-cardDashboard  p-4 m-2  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg col-span-2 lg:col-span-1 shadow-[rgba(0,_0,_0,_0.10)_0px_25px_50px_-12px]   ">
                    
                    <div v-if="account.showDetails" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-end justify-center p-2 text-center  sm:items-center sm:p-0">
                           
                        <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div class="col-span-3 lg:col-span-2  m-2 p-2 bg-TFL-card2  rounded-lg shadow sm:p-4 ">

                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-gray-300  truncate ">
                                                            شماره حساب
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-gray-300  " @click="copyAccountId(account.accountId)" style="cursor: pointer;" title="برای کپی کلیک کنید">
                                                        {{account.accountId}}
                                                    </div>
                                                   
                                                </div>
                                                <hr class="h-px my-2 bg-gray-600 border-0 ">
                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-gray-300  truncate ">
                                                             پسورد
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-gray-300  " @click="copyAccountId(account.password)" style="cursor: pointer;" title="برای کپی کلیک کنید">
                                                        {{account.password}}
                                                    </div>
                                                    
                                                </div>
                                                <hr class="h-px my-2 bg-gray-600 border-0 ">
                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-gray-300  truncate ">
                                                             اینوستور پسورد
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-gray-300  " @click="copyAccountId(account.investorPassword)" style="cursor: pointer;" title="برای کپی کلیک کنید">
                                                        {{account.investorPassword}}
                                                    </div>
                                                    
                                                </div>
                                                <hr class="h-px my-2 bg-gray-600 border-0 ">
                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-gray-300  truncate ">
                                                             بروکر 
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-gray-300  ">
                                                        {{account.broker}}
                                                    </div>
                                                    
                                                </div>
                                                <hr class="h-px my-2 bg-gray-600 border-0 ">
                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-gray-300  truncate ">
                                                             سرور 
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-gray-300  " @click="copyAccountId(account.server)" style="cursor: pointer;" title="برای کپی کلیک کنید">
                                                        {{account.server}}
                                                    </div>
                                                    
                                                </div>
                                                <hr class="h-px my-2 bg-gray-600 border-0 ">
                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-gray-300  truncate ">
                                                             پلتفرم 
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="flex">
                                                        <a v-if="account.broker == 'Roboforex'" href="https://s30.picofile.com/file/8468464068/roboforex4setup.exe.html" target="_blank" class="text-xs bg-blue-500 text-white  rounded rounded-lg p-1 text-center mx-2">
                                                            دانلود ROBOFOREX MT4
                                                        </a>
                                                        <a v-if="account.broker == 'Fxtm'" href="https://s30.picofile.com/file/8468464084/forextimefxtm4setup.exe.html" target="_blank" class="text-xs bg-blue-500 text-white  rounded rounded-lg p-1 text-center mx-2">
                                                            دانلود FXTM MT4
                                                        </a>
                                                        <a v-if="account.broker == 'Tickmill'" href="https://s31.picofile.com/file/8468464018/tickmilleu4setup.exe.html" target="_blank" class="text-xs bg-blue-500 text-white  rounded rounded-lg p-1 text-center mx-2">
                                                            دانلود Tickmill MT4
                                                        </a>
                                                        <a v-if="account.broker == 'ICMarkets'" href="https://s31.picofile.com/file/8468464034/icmarketseu4setup.exe.html" target="_blank" class="text-xs bg-blue-500 text-white  rounded rounded-lg p-1 text-center mx-2">
                                                            دانلود ICMarkets MT4
                                                        </a>
                                                        <a v-if="account.broker == 'Alpari'" href="https://s30.picofile.com/file/8475440084/alpari4setup.exe.html" target="_blank" class="text-xs bg-blue-500 text-white  rounded rounded-lg p-1 text-center mx-2">
                                                            دانلود Alpari MT4
                                                        </a>
                                                        <a v-if="account.broker == 'Exness'" href="https://s30.picofile.com/file/8475448418/exness4setup.exe.html" target="_blank" class="text-xs bg-blue-500 text-white  rounded rounded-lg p-1 text-center mx-2">
                                                            دانلود Exness MT4
                                                        </a>
                                                        <div class="inline-flex items-center  text-gray-300  ">
                                                            {{account.platform}} 
                                                        </div>

                                                    </div>
                                                    
                                                </div>
                                                <hr class="h-px my-2 bg-gray-600 border-0 ">
                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                    <div class="flex-1 min-w-0">
                                                        <p class="text-sm text-right text-gray-300  truncate ">
                                                             لورج 
                                                        </p>
                                                    
                                                    </div>
                                                    <div class="inline-flex items-center  text-gray-300  ">
                                                        1:{{account.leverage}}
                                                    </div>
                                                    
                                                </div>
                                                <hr class="h-px my-2 bg-gray-600 border-0 ">
                                                
                                                </div>
                            <div class="bg-TFL-card2 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                            <button @click="toggleAccount(index)" type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-TFL-base1 sm:ml-3 sm:w-auto">بستن</button>
                            
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    
                    <div v-if="account.showReset" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
                        
                        <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div class="col-span-3 lg:col-span-2 text-right   m-2 p-4 bg-TFL-card1  rounded-lg shadow sm:p-8 ">

                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                    کابر گرامی
                                                </div>
                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                     در صورت دارا بودن شرایط زیر، امکان ریست حساب شما وجود خواهد داشت:
                                                </div>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight text-gray-300 "> حساب شما در سود باشد.</span>
                                                    </li>
                                                </ul>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight text-gray-300 ">نقض قوانین صورت نگرفته باشد    </span>
                                                    </li>
                                                </ul>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight text-gray-300 ">حداکثر {{formatNumber(5)}} روز به پایان زمان چالش باقی‌ مانده باشد        </span>
                                                    </li>
                                                </ul>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight  text-gray-300 ">تمامی پوزیشن‌های حساب بسته باشد  </span>
                                                    </li>
                                                </ul>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight text-gray-300 ">اکانت فعلی ریست نشده باشد</span>
                                                    </li>
                                                </ul>
                                                <hr class="h-px my-2 bg-gray-600 border-0 ">
                                                <div v-if="account.canReset" class="flex text-TFL-green1 items-center space-x-4 my-2 ">
                                                    در حال حاضر حساب شما به مجاز به ریست می‌باشد

                                                    
                                                </div>
                                                <div v-if="!account.canReset" class="flex text-TFL-red1 items-center space-x-4 my-2 ">
                                                    در حال حاضر حساب شما به مجاز به ریست نمی‌باشد

                                                    
                                                </div>
                                                </div>
                                                
                            <div class="bg-TFL-card1 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                                <button @click="showResetFunction(index)" type="button" class="inline-flex w-full  justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 sm:ml-3 sm:w-auto">بستن</button>
                                <button v-if="account.canReset && loadingResponse" @click="ResetRequest(index)" type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-TFL-base1 sm:ml-3 sm:w-auto">ثبت درخواست ریست</button>
                                <button v-if="account.canReset && !loadingResponse"  type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-TFL-base1 sm:ml-3 sm:w-auto"><div role="status justify-self-center">
                        <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>  </button>
                                <div v-if="!account.canReset"  class="inline-flex w-full  justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto">ثبت درخواست ریست</div>
                            </div>
                            
                        </div>
                        </div>
                    </div>
                    </div>

                    
                    <div v-if="account.showExtend" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
                        
                        <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div class="col-span-3 lg:col-span-2 text-right   m-2 p-4 bg-TFL-card1  rounded-lg shadow sm:p-8 ">

                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                    کابر گرامی
                                                </div>
                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                     در صورت دارا بودن شرایط زیر، امکان اکستند حساب شما وجود خواهد داشت:
                                                </div>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight text-gray-300 "> حساب شما در سود باشد.</span>
                                                    </li>
                                                </ul>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight text-gray-300 ">نقض قوانین صورت نگرفته باشد    </span>
                                                    </li>
                                                </ul>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight text-gray-300 ">حداکثر {{formatNumber(5)}} روز به پایان زمان چالش باقی‌ مانده باشد        </span>
                                                    </li>
                                                </ul>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight  text-gray-300 ">تمامی پوزیشن‌های حساب بسته باشد  </span>
                                                    </li>
                                                </ul>
                                               
                                                <hr class="h-px my-2 bg-gray-600 border-0 ">
                                                <div v-if="account.canExtend" class="flex text-TFL-green1 items-center space-x-4 my-2 ">
                                                    در حال حاضر حساب شما به مجاز به اکستند می‌باشد

                                                    
                                                </div>
                                                <div v-if="!account.canExtend" class="flex text-TFL-red1 items-center space-x-4 my-2 ">
                                                    در حال حاضر حساب شما به مجاز به اکستند نمی‌باشد

                                                    
                                                </div>
                                                </div>
                                                
                            <div class="bg-TFL-card1 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                                <button @click="showExtendFunction(index)" type="button" class="inline-flex w-full  justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 sm:ml-3 sm:w-auto">بستن</button>
                                <button v-if="account.canExtend && loadingResponse" @click="ExtendRequest(index)" type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-TFL-base1 sm:ml-3 sm:w-auto">ثبت درخواست اکستند</button>
                                <button v-if="account.canExtend && !loadingResponse"  type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-TFL-base1 sm:ml-3 sm:w-auto"><div role="status justify-self-center">
                        <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>  </button>
                                <div v-if="!account.canExtend"  class="inline-flex w-full  justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto">ثبت درخواست اکستند</div>
                            </div>
                            
                        </div>
                        </div>
                    </div>
                    </div>

                    
                    <div v-if="account.showNextPhase" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
                        
                        <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div class="col-span-3 lg:col-span-2 text-right   m-2 p-4 bg-TFL-card1  rounded-lg shadow sm:p-8 ">

                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                    کابر گرامی
                                                </div>
                                                <div class="flex items-center space-x-4 my-2 text-gray-300 ">
                                                     در صورت دارا بودن شرایط زیر، امکان ثبت درخواست فاز بعدی وجود خواهد داشت:
                                                </div>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight text-gray-300 "> بالانس حساب شما به تارگت سود رسیده باشد</span>
                                                    </li>
                                                </ul>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight text-gray-300 ">نقض قوانین صورت نگرفته باشد    </span>
                                                    </li>
                                                </ul>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight text-gray-300 ">حداقل روزهای معاملاتی تکمیل شده باشد         </span>
                                                    </li>
                                                </ul>
                                                <ul role="list" class="space-y-5 my-7">
                                                    <li class="flex space-x-3 items-center">
                                                        <svg class="flex-shrink-0 w-4 h-4 text-TFL-green1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                        </svg>
                                                        <span class="text-base font-normal leading-tight  text-gray-300 ">تمامی پوزیشن‌های حساب بسته باشد  </span>
                                                    </li>
                                                </ul>
                                               
                                                <hr class="h-px my-2 bg-gray-600 border-0 ">
                                                <div v-if="account.state =='SucceedMinProfit' || account.state=='PendingNextPhase' " class="flex text-TFL-green1 items-center space-x-4 my-2 ">
                                                    در حال حاضر حساب شما به مجاز به ثبت درخواست فاز بعد می‌باشد

                                                    
                                                </div>
                                                <div v-if="account.state =='SucceedMinProfit' || account.state=='PendingNextPhase' "  class="flex text-TFL-red1 text-justify items-center space-x-4 my-2 ">
لازم بذکر است در پراپ‌فرم ترید برای زندگی به منظور تسهیل فرآیند دریافت حساب فاز بعد، با یک کلیک ‌می‌توانید حساب خود را دریافت نمایید. اما توجه نمایید دریافت حساب به معنای تائید نهایی کارشناس نخواهد بود، لذا تریدر موظف است ظرف 24 ساعت درخواست تائید نهایی حساب را در تیکت ثبت نماید و تائید نهایی کارشناس را دریافت کند. در صورت عدم ثبت درخواست، در هنگام برداشت سود، تمامی حساب‌ها مجددا بررسی خواهد شد و در صورت نقض هریک از قوانین در حساب‌های پیشین، حساب معاملاتی مردود خواهد شد.                                                    
                                                </div>
                                                <div v-if="account.state !='SucceedMinProfit' && account.state!='PendingNextPhase'" class="flex text-TFL-red1 items-center space-x-4 my-2 ">
                                                    در حال حاضر حساب شما به مجاز به ثبت درخواست فاز بعد نمی‌باشد

                                                    
                                                </div>
                                                </div>
                                                
                            <div class="bg-TFL-card1 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                                <button @click="showNextPhaseFunction(index)" type="button" class="inline-flex w-full  justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 sm:ml-3 sm:w-auto">بستن</button>
                                <button v-if="(account.state =='SucceedMinProfit' || account.state=='PendingNextPhase') && loadingResponse" @click="NextPhaseRequest(index)"   type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-TFL-base1 sm:ml-3 sm:w-auto">ثبت درخواست فاز بعدی</button>
                                <button v-if="(account.state =='SucceedMinProfit' || account.state=='PendingNextPhase') && !loadingResponse"    type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-TFL-base1 sm:ml-3 sm:w-auto"><div role="status justify-self-center">
                        <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div></button>
                                <div v-if="account.state !='SucceedMinProfit' && account.state!='PendingNextPhase'"  class="inline-flex w-full  justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto">ثبت درخواست فاز بعدی</div>
                            </div>
                            
                        </div>
                        </div>
                    </div>
                    </div>
                    
                

                    <div v-if="showExtendSuccessModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
                            
                            <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-TFL-card1 text-gray-300  rounded-lg shadow sm:p-8 ">
                                
                                            <div class="flex items-center text-center space-x-4 my-2 ">اکانت شما با موفقیت اکستند شد.</div>
                                            <div class="flex items-center text-center space-x-4 my-2 ">به تاریخ پایان چالش شما {{ formatNumber(14) }} روز افزوده شد.</div>
                                                
                                </div>
                                                
                            <div class="bg-TFL-card1 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                                <button @click="showSuccesExtendModalFunction" type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-TFL-base1 sm:ml-3 sm:w-auto">بستن</button>
                                
                            </div>
                            
                        </div>
                        </div> 
                    </div>
                    </div>
                    
                

                    <div v-if="showResetSuccessModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
                            
                            <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-TFL-card1 text-gray-300  rounded-lg shadow sm:p-8 ">
                                
                                            <div class="flex items-center text-center space-x-4 my-2 ">اکانت شما با موفقیت ریست شد.</div>
                                            <div class="flex items-center text-center space-x-4 my-2 ">اکانت جدید به لیست حساب‌های شما افزوده خواهد شد.</div>
                                                
                                </div>
                                                
                            <div class="bg-TFL-card1 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                                <button @click="showSuccesResetModalFunction" type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-TFL-base1 sm:ml-3 sm:w-auto">بستن</button>
                                
                            </div>
                            
                        </div>
                        </div> 
                    </div>
                    </div>
                    
                    <div v-if="showNextPhaseSuccessModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
                            
                            <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-TFL-card1 text-gray-300  rounded-lg shadow sm:p-8 ">
                                
                                            <div class="flex items-center text-center space-x-4 my-2 ">درخواست فاز بعدی شما با موفقیت تائید شد</div>
                                            <div class="flex items-center text-center space-x-4 my-2 ">اکانت جدید به لیست حساب‌های شما افزوده خواهد شد.</div>
                                                
                                </div>
                                                
                            <div class="bg-TFL-card1 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                                <button @click="showNextPhaseSuccessModalFunction" type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-TFL-base1 sm:ml-3 sm:w-auto">بستن</button>
                                
                            </div>
                            
                        </div>
                        </div> 
                    </div>
                    </div>
                    <div v-if="showNextPhaseFailedModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
                            
                            <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-TFL-card1 text-gray-300  rounded-lg shadow sm:p-8 ">
                                
                                            <div class="flex items-center text-center space-x-4 my-2 ">ثبت درخواست فاز بعدی با خطا مواجه شد.</div>
                                            
                                                
                                </div>
                                                
                            <div class="bg-TFL-card1 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                                <button @click="showNextPhaseFailedModalFunction" type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-TFL-base1 sm:ml-3 sm:w-auto">بستن</button>
                                
                            </div>
                            
                        </div>
                        </div> 
                    </div>
                    </div>




                   
                    <div class="account-title  w-full grid grid-cols-3 justify-self-center  ">
                       
                        <div class="relative col-span-3 lg:col-span-2 grid grid-rows-11   my-2 p-2 bg-TFL-card1  rounded-lg shadow sm:py-6 sm:px-8 ">
                            <div v-if="account.plan == 'Limited'" class="absolute  -top-2 mx-8 px-1 text-sm bg-purple-500 rounded text-white">چالش دارای محدودیت زمانی - نوع {{ formatNumber(1) }}</div>
                            <div v-if="account.plan == 'NoLimit'" class="absolute  -top-2 mx-8 px-1 text-sm bg-green-500 rounded text-white">چالش بدون محدودیت زمانی - نوع {{ formatNumber(1) }}</div>
                            <div v-if="account.plan == 'Limited6'" class="absolute  -top-2 mx-8 px-1 text-sm bg-purple-500 rounded text-white">چالش دارای محدودیت زمانی - نوع {{ formatNumber(2) }}</div>
                            <div v-if="account.plan == 'NoLimit6'" class="absolute  -top-2 mx-8 px-1 text-sm bg-green-500 rounded text-white">چالش بدون محدودیت زمانی - نوع {{ formatNumber(2) }}</div>
                            <div v-if="account.plan == 'Rapid'" class="absolute  -top-2 mx-8 px-1 text-sm bg-orange-600 rounded text-white">پلن مستقیم قدیم</div>
                            <div v-if="account.plan == 'Rapid2'" class="absolute  -top-2 mx-8 px-1 text-sm bg-orange-600 rounded text-white">پلن مستقیم</div>
                            <div v-if="account.plan == 'Multiple'" class="absolute  -top-2 mx-8 px-1 text-sm bg-blue-600 rounded text-white">5X</div>
                            <div v-if="account.plan == 'Competition'" class="absolute  -top-2 mx-8 px-1 text-sm bg-red-600 bg-opacity-50 rounded text-white">مسابقه  </div>
                            <div class="flex items-center space-x-4 my-2 text-gray-300  ">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-TFL-base1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                    </svg>

                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-gray-300  mx-2 truncate ">
                                         شماره حساب
                                    </p>
                                
                                </div>
                                <div v-if="account.accountId" class="inline-flex items-center  text-gray-300  ">
                                    {{formatNumber(account.accountId).replace(/,/g,'')}}
                                </div>
                            </div>
                            <hr class="h-px bg-gray-600 border-0 ">
                            <div class="flex items-center space-x-4 my-2 text-gray-300">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-TFL-base1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                    </svg>


                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-gray-300  mx-2 truncate ">
                                          موجودی اولیه
                                    </p>
                                
                                </div>
                                <div v-if="account.deposit" class="inline-flex items-center  text-gray-300  ">
                                    {{formatNumber(account.deposit).replace(/,/g,'')}}
                                </div>
                            </div>
                            <hr class="h-px bg-gray-600 border-0 ">
                            <div class="flex items-center space-x-4 my-2 text-gray-300">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-TFL-base1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                                    </svg>

                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-gray-300  mx-2 truncate ">
                                           وضعیت
                                    </p>
                                
                                </div>
                                <div v-if="account.state === 'Active'" class="inline-flex items-center bg-TFL-base1 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded  ">
                                    
                                     فعال
                                </div>
                                <div v-if="account.state === 'Failed'" class="inline-flex items-center bg-TFL-red1 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    مردود
                                </div>
                                <div v-if="account.state === 'ReviewNeeded'" class="inline-flex items-center bg-orange-300 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    بررسی کارشناس
                                </div>
                                <div v-if="account.state === 'Reseted'" class="inline-flex items-center bg-gray-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    ریست شده
                                </div>
                                <div v-if="account.state === 'Expired'" class="inline-flex items-center bg-TFL-red1 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    منقضی شده
                                </div>
                                <div v-if="account.state === 'SucceedMinProfit'" class="inline-flex items-center bg-purple-500 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    تارگت سود
                                </div>
                                <div v-if="account.state === 'PendingNextPhase'" class="inline-flex items-center bg-purple-500 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    تارگت سود
                                </div>
                                <div v-if="account.state === 'Passed'" >
                                    <div class="inline-flex items-center bg-blue-500 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">

                                        پاس
                                    </div>
                                    <button v-if="account.type == 'DemoPhase2' || account.type == 'Real' " @click="getCert(index)" class="inline-flex items-center bg-TFL-base1 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded hover:bg-TFL-base1">دانلود سرتیفیکت</button>
                                    
                                </div>
                                
                            </div>
                            <hr class="h-px bg-gray-600 border-0 ">
                            <div class="flex items-center space-x-4 my-2 text-gray-300">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-TFL-base1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                                    </svg>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-gray-300  mx-2 truncate ">
                                        فاز
                                    </p>
                                </div>
                                <div v-if="account.type == 'DemoPhase1' && account.plan !== 'Multiple'" class="inline-flex items-center  text-gray-300  ">
                                    فاز اول
                                </div>
                                <div v-if=" account.plan == 'Multiple'" class="inline-flex items-center  text-gray-300  ">
                                    ریل
                                </div>
                                <div v-if="account.type == 'DemoPhase2'" class="inline-flex items-center  text-gray-300  ">
                                    فاز دوم
                                </div>
                                <div v-if="account.type == 'Real'" class="inline-flex items-center  text-gray-300  ">
                                    ریل 
                                </div>
                            </div>
                            <hr class="h-px bg-gray-600 border-0 ">
                            <div class="flex items-center space-x-4 my-2 text-gray-300">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-TFL-base1">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                    </svg>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p  class="text-sm text-gray-300  mx-2 truncate ">
                                        تاریخ شروع
                                    </p>
                                </div>
                                <div v-if="account.firstTradeDateTime" class="inline-flex items-center  text-gray-300  ">
                                    {{ formatNumber(account.firstTradeDateTime.replace("T", " at ").replace("+00:00", "").substring(0, 10) )}}
                                </div>
                                <div v-if="!account.firstTradeDateTime" class="inline-flex items-center  text-gray-300  ">
                                   در انتظار اولین ترید
                                </div>
                            </div>
                            <hr class="h-px bg-gray-600 border-0 ">
                            <div class="flex items-center space-x-4 my-2 text-gray-300">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-TFL-base1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                    </svg>

                                </div>
                                <div class="flex-1 min-w-0">
                                    <p v-if="account.type != 'Real'"   class="text-sm text-gray-300  mx-2 truncate ">
                                        تاریخ پایان
                                    </p>
                                    <p v-if="account.type == 'Real'"   class="text-sm text-gray-300  mx-2 truncate ">
                                        سهم سود 
                                    </p>
                                
                                </div>
                                <div v-if="account.expireTime && account.plan=='Limited' && account.type != 'Real' " class="inline-flex items-center  text-gray-300  ">
                                    {{formatNumber(account.expireTime.replace("T", " at ").replace("+00:00", "").substring(0, 10))}}
                                </div>
                                <div v-if="!account.expireTime && ((account.plan=='Limited' && account.type != 'Real') || (account.plan=='Limited6' && account.type != 'Real') )" class="inline-flex items-center  text-gray-300  ">
                                    در انتظار اولین ترید
                                </div>
                                <div v-if=" account.plan=='NoLimit' || account.plan=='NoLimit6' || account.type == 'Real' || account.plan == 'Multiple'" class="inline-flex items-center  text-gray-300  ">
                                    بدون محدودیت زمانی   
                                </div>
                                <div v-if="  account.type == 'Real'" class="inline-flex items-center  text-gray-300  ">
                                {{ formatNumber(account.sharedPercent) }} %      
                                </div>
                            </div>
                            
                                
                    
                                
                            
                            
                        </div>
                        <div class="col-span-3  lg:col-span-1    my-2 p-2  bg-TFL-card1  rounded-lg shadow   mx-0 lg:mx-2 grid grid-rows-5">
                            <router-link :to="{params: {id:account.uId }, name:'DAnalysis' }"  class="relative inline-flex items-center justify-center px-2 py-2 my-2 overflow-hidden font-medium text-TFL-base1 transition duration-300 ease-out border-2 border-TFL-base1 hover:border-2 hover:border-TFL-base1 rounded-lg shadow-md group">
                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 translate-x-full bg-TFL-base1 group-hover:translate-x-0 ease">
                                    <svg class="w-6 h-6  flip-right-to-left" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                    </svg>
                                </span>
                                <span class="absolute flex items-center justify-center w-full h-full text-TFL-base1 transition-all duration-300 transform group-hover:translate-x-full ease"> آنالیز حساب</span>
                                <span class="relative invisible">Button Text</span>
                            </router-link>
                            <button v-if="((account.type == 'DemoPhase1' )  && account.state !== 'Failed' && account.state !== 'Passed') && (account.plan == 'Limited' || account.plan == 'Limited6') "  @click="showResetFunction(index)"  class="inline-flex  items-center justify-center  py-2 my-2 font-medium tracking-wide text-gray-500 transition duration-200 border-2 border-gray-500 rounded-lg hover:bg-gray-800 hover:text-TFL-green1 ">
                             درخواست ریست
                            </button>
                            <button v-if="((account.type == 'DemoPhase1' )  && account.state !== 'Failed' && account.state !== 'Passed') && (account.plan == 'Limited' || account.plan == 'Limited6') "  @click="showExtendFunction(index) " class="inline-flex items-center justify-center  py-2 my-2 font-medium tracking-wide text-gray-500 transition duration-200 border-2 border-gray-500 rounded-lg hover:bg-gray-800 hover:text-TFL-green1 ">
                             درخواست اکستند
                            </button>
                            <button v-if="account.plan != 'Multiple' && account.type != 'Real' && account.state !== 'Failed' && account.state !== 'Passed' " @click="showNextPhaseFunction(index)" to="/Dashboard/DWhitdrawal" class="inline-flex items-center justify-center  py-2 my-2 font-medium tracking-wide text-gray-500 transition duration-200 border-2 border-gray-500 rounded-lg hover:bg-gray-800 hover:text-TFL-green1 ">
                             درخواست فاز بعدی
                            </button>
                            <router-link v-if="(account.type == 'Real' || account.plan == 'Multiple') && account.state !== 'Passed' && account.state !== 'Failed'" to="/Dashboard/Dwithdrawal" class="inline-flex items-center justify-center text-center  py-2 my-2 font-medium tracking-wide text-gray-500 transition duration-200 border-2 border-gray-500 rounded-lg hover:bg-gray-800 hover:text-TFL-green1 ">
                             درخواست برداشت
                            </router-link>
                            <button  @click="toggleAccount(index)" class="relative  py-2 my-2 items-center  justify-center font-medium text-white text-center transition duration-300 bg-TFL-base1 rounded-md hover:bg-TFL-base1 ease">
                            <span class="absolute bottom-0 left-0 h-full -ml-2">
                            </span>
                            <span class="absolute top-0 right-0 w-12 h-full -mr-3">
                            </span>
                            <span class="relative items-center justify-center text-center "> اطلاعات حساب</span>
                            </button>
                            


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
   


    
        
    
</template>


<script>
import axios from 'axios';
import { digitsEnToFa } from 'persian-tools';

import URL from "../URL";





export default {
    
    
    data(){
        return {
      accounts: [],
      guid:'',
      firstTradeDateTimeTitle:'',
      expireTimeTitle:'',
      accountUIDfromAccountList: '',
      showExtendSuccessModal: false,
      showResetSuccessModal: false,
      showNextPhaseSuccessModal: false,
      showNextPhaseFailedModal: false,
      fileData: null,
      gotResponse : false,
      loadingResponse: true,
      accountLoaded:false,
               

    };
  },
  methods: {
    copyAccountId(accountId) {
      navigator.clipboard.writeText(accountId)
        
    },
    formatNumber(number) {
      const formattedNumber = number.toLocaleString('en-US');
      return digitsEnToFa(formattedNumber);
    },
    toggleAccount(index) {
      this.accounts[index].showDetails = !this.accounts[index].showDetails;
    },
    showExtendFunction(index) {
        this.accounts[index].showExtend = !this.accounts[index].showExtend;
    },
    showResetFunction(index) {
      
      this.accounts[index].showReset = !this.accounts[index].showReset;
    },
    showNextPhaseFunction(index) {
      this.accounts[index].showNextPhase = !this.accounts[index].showNextPhase;
    },
    selectAccount(index) {
        // Get the account ID from the account object
       
        this.accountUIDfromAccountList = this.accounts[index].uId;
        // EventBus.$emit('data-emitted', this.accountUIDfromAccountList);
        console.log('inshod :', this.accountUIDfromAccountList)
        // window.location.href = `/Dashboard/Home`;
        window.open(`/Dashboard/Home`, '_blank');
    },
    showSuccesExtendModalFunction(){
        this.showExtendSuccessModal = !this.showExtendSuccessModal;

    },
    showSuccesResetModalFunction(){
        this.showResetSuccessModal = !this.showResetSuccessModal;

    },
    showNextPhaseSuccessModalFunction(){
        this.showNextPhaseSuccessModal = !this.showNextPhaseSuccessModal;

    },
    showNextPhaseFailedModalFunction(){
        this.showNextPhaseFailedModal = !this.showNextPhaseFailedModal;

    },

    ExtendRequest(index) {
        this.loadingResponse = false;
        const url = URL.baseUrl + 'Meta/account/extend'
        axios.post(url, {
    accountId: this.accounts[index].uId,
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  .then(response => {
    
        
        this.showExtendSuccessModal = true;
        this.getAccount();
        this.loadingResponse = true;
      
    
  })
  .catch(error => {
    
    this.loadingResponse = true;
  });

    },
    ResetRequest(index) {
        this.loadingResponse = false;
        const url = URL.baseUrl + 'Meta/account/Reset'
        axios.post(url, {
    accountId: this.accounts[index].uId,
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  .then(response => {
    
      // show success modal
      this.showResetSuccessModal = true;
      this.loadingResponse = true;
      this.getAccount();
   
      
      
    
  })
  .catch(error => {
    
    this.loadingResponse = true;
  });

    },
    NextPhaseRequest(index) {
        this.loadingResponse = false;
        const url = URL.baseUrl + 'Meta/account/NextPhase'
        axios.post(url, {
    accountId: this.accounts[index].uId,
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  .then(response => {
    
      // show success modal
      this.showNextPhaseSuccessModal = true;
      this.loadingResponse = true;
      
      this.getAccount();
    
  })
  .catch(error => {
    
    this.showNextPhaseFailedModal = true;
    this.loadingResponse = true;

  });

    },
    getCert(index) {
  const accountId = this.accounts[index].uId;
  const token = localStorage.getItem('token');
  const url = URL.baseUrl + `Meta/account/cert/${accountId}`;

  axios
    .get(url, {
      headers: {
        'Authorization': 'Bearer ' + token
      },
      responseType: 'blob' // Set the response type to 'blob'
    })
    .then(response => {
      // Create a URL for the Blob
      const fileData = window.URL.createObjectURL(response.data);

      // Create a temporary <a> element
      const link = document.createElement('a');
      link.href = fileData;
      link.download = 'certificate.pdf'; // Set the desired file name

      // Append the <a> element to the document
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up the temporary <a> element
      document.body.removeChild(link);
    })
    .catch(error => {
      
    });
},
getAccount(){
    const url = URL.baseUrl + `Meta/accounts`
    axios.get(url, {
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
    .then(response => {
        // Add the show variable to each account object
        this.accounts = response.data.map(account => {
        return {
            ...account,
            showDetails: false,
            showReset: false,
            showExtend: false,
            showNextPhase: false,
        };
        });
        this.gotResponse = true;
        this.accountLoaded = true;
    })
    .catch(error => {
        this.accountLoaded = true;
    });
    },


},
mounted(){
    this.getAccount();
    

},
  computed: {
    formattedDate() {
      if (this.account.firstTradeDateTime !== "") {

        //this.firstTradeDateTimeTitle = this.account.firstTradeDateTime.substring(0, 10);
        
        // account.firstTradeDateTime.replace("T", " at ").replace("+00:00", "")
      } 
      if (this.account.expireTime !== "") {

        //this.expireTimeTitle = this.account.expireTime.substring(0, 10);
        
        //account.expireTimeTitle=account.expireTimeTitle.replace("T", " at ").replace("+00:00", "")
      } 
      if (this.account.createDateTime !== "") {

        
        
        //account.createDateTime=account.createDateTime.replace("T", " at ").replace("+00:00", "")
      } 

    },
  },
  
  
};

</script>
<style>
  .flip-right-to-left {
    transform: scaleX(-1);
  }
</style>

